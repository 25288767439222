@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */

@font-face {
  font-family: "AlianzaSlab100";
  font-weight: 100;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab100.otf")
      format("opentype");
}

@font-face {
  font-family: "AlianzaSlab200";
  font-weight: 200;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab200.otf")
      format("opentype");
}
@font-face {
  font-family: "AlianzaSlab300";
  font-weight: 300;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab300.otf")
      format("opentype");
}

@font-face {
  font-family: "AlianzaSlab400";
  font-weight: 400;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab400.otf")
      format("opentype");
}

@font-face {
  font-family: "AlianzaSlab500";
  font-weight: 500;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab500.otf")
      format("opentype");
}

@font-face {
  font-family: "AlianzaSlab600";
  font-weight: 600;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab600.otf")
      format("opentype");
}
@font-face {
  font-family: "AlianzaSlab700";
  font-weight: 700;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab700.otf")
      format("opentype");
}
@font-face {
  font-family: "AlianzaSlab800";
  font-weight: 800;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab800.otf")
      format("opentype");
}
@font-face {
  font-family: "AlianzaSlab900";
  font-weight: 900;
  src:
    local("AlianzaSlab"),
    url("../assets/fonts/AlianzaSlab/CorradineFonts-AlianzaSlab900.otf")
      format("opentype");
}

.grid--border {
  @apply pb-8 border-b border-gray-400  mb-4;
}

.basic-title {
  @apply font-Alianza700 text-lg opacity-80;
}

.main-title {
  @apply mb-2.5 font-Alianza700 text-2xl text-gray-950 opacity-80;
}

.basic-transition {
  @apply transition-all duration-100 ease-linear;
}

.grid-layout {
  @apply col-span-12 grid grid-cols-12 items-center gap-3;
}

.input-styles {
  @apply block h-[45px] w-full rounded-md border-0 border-none bg-white bg-opacity-50 px-2.5 py-2.5 text-gray-800 shadow-sm outline-none ring-[0.5px] ring-inset ring-gray-800 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-100 disabled:bg-transparent read-only:bg-transparent;
}

.textarea-styles {
  @apply block w-full rounded-md border-0 border-none bg-white bg-opacity-50 px-2.5 py-2.5 text-gray-800 shadow-sm outline-none ring-[0.5px] ring-inset ring-gray-800 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-100 disabled:bg-transparent read-only:bg-transparent;
}

.date-styles {
  @apply block w-full h-[45px] rounded-md border-0 border-none bg-white bg-opacity-50 text-gray-800 shadow-sm outline-none ring-[0.5px] ring-inset ring-gray-800 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-100 disabled:bg-transparent read-only:bg-transparent;
}

.default_input_error {
  @apply h-[20px];
}

.form__buttons {
  @apply col-span-12 grid grid-cols-[2fr,3fr] gap-2.5 duration-300 ease-in-out;
}

.scale-phone {
  @apply scale-[.9] md:scale-[.9] xl:scale-[.8];
}

input.input-styles[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
}

.DayPickerInput input {
  width: 100%;
  height: 42px;
  background-color: transparent;
  border: 1px solid rgba(54, 52, 49, 0.2);
  border-radius: var(--radius);
  padding: 14px;
  color: #363431cc;
}

.ant-picker {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}

.ant-picker-input input::placeholder {
  color: black;
}

/*
===============
CSS Variables
===============
*/
:root {
  --clr-primary: hsl(9, 89%, 61%);
  --clr-primary-2: hsl(9, 89%, 71%);
  --clr-primary-light: hsl(9, 89%, 98%);
  --clr-background: hsl(60, 24%, 93%);
  --clr-dark-grey: hsl(36, 5%, 20%);
  --clr-medium-grey: rgb(54, 52, 49, 0.6);
  --clr-grey: hsl(212, 33%, 89%);
  --clr-success: hsl(134, 61%, 41%);
  --clr-danger: hsl(354, 70%, 54%);
  --clr-warning: hsl(45, 100%, 51%);
  --clr-info: hsl(188, 78%, 41%);
  --clr-white: #fff;
  --clr-black: #222;
  --clr-menu-black: #363431;
  --transition: all 0.1s linear;
  --spacing: 0.1rem;
  --radius: 4px;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: var(--clr-background);
  color: var(--clr-black);
  font-family:
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  height: 100%;
}
ul {
  list-style-type: none;
  margin-bottom: 0;
}
a,
a:hover {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 12px;
  font-family: "AlianzaSlab700";
  opacity: 0.9;
  color: var(--clr-black);
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 14px;
}
p {
  margin-bottom: 16px;
}

.zoom {
  zoom: 0.8;
}

/* dashboard layout of CMS */

.no-scrollbar::webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard {
  display: grid;
  grid-template-columns: auto;
}

.dashboard-page {
  padding-top: 92.5px;
  padding-bottom: 50px;
  margin-left: 250px;
  transition: var(--transition);
}

.dashboard-hide-sidebar {
  margin-left: 60px;
}

.dashboard-content {
  margin: 0 auto;
  width: 90%;
}
.dashboard_sidebar-open {
  margin-left: 280px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* Remove arrows from Number Input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* End */

.pageTitle {
  font-family: "AlianzaSlab700";
  opacity: 0.8;
  font-size: 24px;
  color: var(--clr-black);
  margin-bottom: 10px;
}

label,
.title {
  color: var(--clr-medium-grey);
}

.required:after {
  content: " *";
  color: var(--clr-primary);
}

.errorMessage {
  display: relative;
  color: var(--clr-primary);
}

.error {
  color: #e74c3c !important;
  /* font-size: 16px; */
}

/* General Styles from Recipe */

.titleLink {
  color: inherit !important;
  cursor: pointer;
}
.titleLink:hover {
  text-decoration: underline;
}
.coverImg {
  width: 70px;
  height: 70px;
  border-radius: 4px;
}

.title {
  font-size: 16px;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(54, 52, 49, 0.6) !important;
}

/* Recipe Detail CSS */

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 2px !important;
}

.a-title {
  font-family: "AlianzaSlab700" !important;
  opacity: 0.83 !important;
  font-size: 18px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363431 !important;
}

.createLink {
  cursor: pointer;
  font-family: "AlianzaSlab700" !important;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #f45e45 !important;
  text-transform: uppercase;
}

.createLink:hover {
  text-decoration: underline;
}

.title-s {
  opacity: 0.8;
  font-family: "AlianzaSlab700";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(54, 52, 49, 0.8) !important;
}

.cal-table .MuiTabs-scroller {
  flex: none !important;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  width: 80%;
}

.MuiPaper-root {
  background-color: #f2f2ea !important;
  color: rgba(54, 52, 49, 0.8) !important;
}

.MuiToolbar-root {
  min-height: 0 !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

@media screen and (max-width: 640px) {
  .cal-table {
    padding-top: 2.5em !important;
  }
}

.rem .c-container {
  display: inline;
  font-family: "AlianzaSlab400";
  margin-left: -2.6em;
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363431;
}

/* grids for pages and forms */
.page {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}

/* Edit step Modal styles */

.MuiDialogContent-root .ing-list {
  text-align: left !important;
}

.wide-modal .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 800px !important;
}

.extra-wide-modal .MuiDialog-paperWidthSm {
  max-width: 1000px !important;
  width: 1000px !important;
}

.MuiDialogContent-root .mu-title {
  text-align: left !important;
}

span[contenteditable="true"] {
  width: 40px;
  padding: 5px;
}

/* RecipeTable Styles*/

.MuiPaper-elevation2 {
  box-shadow: none !important;
}
.titleLink {
  color: inherit !important;
  cursor: pointer;
}
.titleLink:hover {
  text-decoration: underline;
}
.coverImg {
  width: 70px;
  height: 70px;
  border-radius: 4px;
}

.coverImg-steps {
  min-width: 60px;
  max-width: 60px;
  height: 40px;
  border-radius: 4px;
}
.coverImg-steptemp {
  width: 80px;
  height: auto;
  border-radius: 4px;
}

.MuiPaper-root {
  background-color: #f2f2ea !important;
  color: rgba(54, 52, 49, 0.8) !important;
}

.MuiDialogContent-root {
  padding-top: 62px;
  background: #f2f2ea;
  text-align: center;
  font-size: 16px;
  color: rgba(54, 52, 49, 0.8) !important;
}

.MuiDialogActions-root {
  background: #f2f2ea;
  justify-content: center !important;
}

.MuiDialog-paperScrollPaper {
  padding: 30px;
}

.MuiTablePagination-toolbar {
  padding-top: 20px !important;
}

.MuiIconButton-root.Mui-disabled {
  opacity: 0;
}

.MuiToolbar-root.MuiToolbar-gutters {
  padding-left: 0;
}

.accordion {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.8s ease-in-out;
}

.accordion.expanded {
  max-height: 2000px;
}

.step-ing-list {
  flex-direction: column; /* Default flex direction for small screens */

  @media screen and (min-width: 725px) {
    flex-direction: row; /* Change to row for screens wider than 725px */
  }
}

.MuiSlider-root.MuiSlider-colorPrimary {
  color: var(--clr-primary);
}

div:focus-visible {
  outline: none;
}

.p-treenode-children .p-tree-toggler {
  margin-left: 25px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: var(--clr-primary-light);
  color: var(--clr-primary);
}

.p-treeselect-filter-container > svg {
  position: absolute;
  top: 8px;
}

.p-treeselect-label {
  white-space: normal;
}

input.p-treeselect-filter.p-inputtext.p-component {
  padding: 4px 16px;
}

.p-treetable-tbody > tr {
  background-color: transparent;
  border-bottom: 1px dotted grey;
}

.p-treetable-thead > tr > th {
  background-color: transparent;
  border-bottom: 1px dotted grey;
  padding-bottom: 4px;
}

.p-treetable-tbody > tr > td {
  display: table-cell;
  padding: 4px;
}

.p-column-header-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.p-treetable-thead,
.p-treetable-header,
.p-paginator {
  background-color: transparent;
}

.p-paginator {
  padding: 8px 0;
}

.MuiTableSortLabel-icon {
  max-height: 18px;
}
